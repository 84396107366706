<template>
<v-container class="my-10">
<p style="text-align: center;"><strong class="pa-2 elevation-5">CONFIGURANDO CONECTOR LOOKER STUDIO</strong></p>
<p><strong><u>NO INÚMERO</u></strong></p>
<p>Para disponibilizar dados do INÚMERO para sistemas externos como o Looker Studio é
	necessário criar um "Relatório Dinâmico"; conforme abaixo:
</p>
<ul>
<li>No site do INÚMERO (<a href="https://inumero.com.br">https://inumero.com.br</a>) faça login com seu usuário.</li>
<li>Selecione a opção do menu "Relatórios Dinâmicos". 
	Se essa opção não estiver no menu, converse com o administrador do sistema da sua 
	empresa para conceder permissão de acesso para seu usuário.
</li>
<li>Selecione um relatório existente ou crie um novo. Anote o número do ID para usar no Looker Studio.</li>
<li>No campo "Grupos" selecione "Acesso remoto via API".</li>
<li>Ainda na tela do relatório, vá na aba Token e crie um novo token ou copie um existente.</li>
</ul>
<br><br>
<p><strong><u>NO LOOKER STUDIO</u></strong></p>
<ul>
<li>Em "Adcionar dados" pesquise por "INUMERO ERP connector".</li>
<li>Selecione o conector do INÙMERO que estará listado nos conectores de parceiros.</li>
<li>Preencha o campo "Token Inúmero" com o valor gera nos procedimentos acima.</li>
<li>Preencha o campo "ID Relatório Dinâmico" obtido acima.</li>
<li>Clique no botão "Adicionar".</li>
</ul>
<br><br>
<p>
	Conheça os 
	<v-btn text to="/contrato">
		<span style="font-size: 11px;">Termos de uso do INÙMERO</span>
	</v-btn>
	e nossa 
	<v-btn text to="/politica">
		<span style="font-size: 11px;">Política de Privacidade</span>
	</v-btn>
</p>
<v-btn 
  absolute bottom right icon
  @click="openWhatsapp"
>
  <v-img src="img/whatsapp.png"></v-img>
</v-btn>
</v-container>
</template>

<script>
  import { isMobile } from '@/js/base/misc'

  export default {
    methods: {
      openWhatsapp () {
        let url = '.whatsapp.com/send?phone=554133445444&text=Olá, meu chamo ..... e preciso de ajuda para .....'
        url = 'https://' + (isMobile() ? 'api' : 'web') + url
        window.open(url, '_blank')
      }
    }
  }
</script>
